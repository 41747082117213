import { ResourceCategoryDto } from '../services/ResourceCategoryService'
import ResourceCard from './ResourceCard'
import * as Constants from "../constants"
import Vector from "../public/assets/vector.png"
import { CommunityCollegesDto } from '../services/CommunityCollegeSearchService'

interface Props {
    categories: ResourceCategoryDto[]
    selectedSchool: CommunityCollegesDto | null
    isLoading: boolean
}


const ResourceCategorySection = (props: Props) => {
    const { categories, selectedSchool, isLoading } = props

    const mentalHealthCategories = categories.filter(item => item.resourceType === Constants.mentalHealthResourceType)
    mentalHealthCategories.sort((a, b) => a.title.localeCompare(b.title))

    const serviceCategories = categories.filter(item => item.resourceType === Constants.servicesResourceType)

    const skeletonResource: ResourceCategoryDto = {
        title: "",
        description: "",
        iconUrl: "",
        resourceType: "",
        active: false,
        smallIconUrl: "",
    }

    const backgroundStyle = {
        backgroundImage:`url(../public/assets/background-bubbles.png)`,
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat"
    }

    return (
        <div className="bg-mhicc-light-gray" style={backgroundStyle}>
            {/* image only displays when resources for a selected school are being displayed above it*/}
            {selectedSchool &&  <img alt='' src={Vector} className='w-full'/> }
            <div className="px-7 pt-12 md:px-20 md:pt-18 lg:pt-28 font-inter"> 
                <div className="text-mhicc-purple text-sm pb-3 font-bold md:text-xl lg:pb-12">
                    <span>HOW RESULTS ARE ORGANIZED</span>
                </div>
                <div className="text-mhicc-dark-slate text-2xl md:text-3xl font-semibold grid grid-cols-1 pb-6 lg:flex lg:flex-row">
                    <div className='flex pb-3  pr-3 lg:w-1/4'>
                        <span>{Constants.mentalHealthResourceType}</span>
                    </div>
                    <div className='lg:grid lg:grid-cols-3 lg:grid-flow-col md:grid-cols-3 gap-4 w-auto md:w-3/4 lg:w-full'>
                        {
                            isLoading ?
                                Array.from({ length: 3 }, (_, k) => (
                                    <div key={k} className=" bg-white rounded-lg flex flex-col mb-4 hover:border-mhicc-blue border-4 border-transparent transition ease-out duration-150">
                                        <ResourceCard resource={skeletonResource} isLoading={isLoading} />
                                    </div>
                                ))
                                :
                                mentalHealthCategories.map((resource, index) => {
                                    return (
                                        <div key={index} className=" bg-white rounded-lg flex flex-col mb-4 hover:border-mhicc-blue border-4 border-transparent transition ease-out duration-150">
                                            <ResourceCard resource={resource} isLoading={isLoading} />
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
                <div className="text-mhicc-dark-slate text-2xl md:text-3xl font-semibold grid grid-cols-1 pb-6 lg:flex lg:flex-row">
                    <div className='flex pb-3 pr-3 lg:w-1/4'>
                        <span>{Constants.servicesResourceType}</span>
                    </div>
                    <div className='lg:grid lg:grid-rows-2 lg:grid-flow-col md:grid-cols-3 gap-4  md:w-3/4 lg:w-full 3xl:grid-rows-1'>
                        {

                            isLoading ?
                                Array.from({ length: 5 }, (_, k) => (
                                    <div key={k} className="bg-white rounded-lg flex flex-col mb-4 hover:border-mhicc-blue border-4 border-transparent transition ease-out duration-150">
                                        <ResourceCard resource={skeletonResource} isLoading={isLoading} />
                                    </div>
                                ))
                                :
                                serviceCategories.map((service, index) => {
                                    return (
                                        <div key={index} className=" bg-white rounded-lg flex flex-col mb-4 hover:border-mhicc-blue border-4 border-transparent transition ease-out duration-150">
                                            <ResourceCard resource={service} isLoading={isLoading} />
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourceCategorySection