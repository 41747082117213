import { FocusEvent, MouseEvent, useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { CommunityCollegesDto } from "../services/CommunityCollegeSearchService"
import Search from "../public/icons/search.png"
import Expand from "../public/icons/expand_more_white.png"
import * as Constants from "../constants"

interface Props {
    communityColleges: CommunityCollegesDto[]
    setSelectedSchool: any
    selectedSchool: CommunityCollegesDto | null
}

const SearchBar = (props: Props) => {
    const { communityColleges, setSelectedSchool, selectedSchool } = props

    // set initial state to the whole list of schools for initial display
    const [searchResults, setSearchResults] = useState<CommunityCollegesDto[]>(communityColleges)

    const [searchDropdownOpen, setSearchDropdownOpen] = useState<boolean>(false)

    const [inputIsFocused, setInputIsFocused] = useState<boolean>(false)

    const [dropdownIsFocused, setDropdownIsFocused] = useState<boolean>(false)

    const noResults: CommunityCollegesDto = {
        title: Constants.noResults,
        active: false,
        resources: []
    }

    const fuseOptions = {
        threshold: 0.3,
        keys: [
            'title',
        ],
    }

    const fuse = new Fuse(communityColleges, fuseOptions)

    useEffect(() => {
        document.addEventListener('click', event => handleClickOutside(event), true);
    }, [])

    const handleClickOutside = (event: globalThis.MouseEvent) => {
        var isInSearchbar = false
        var isInDropdown = false
        var isInSearchResults = false

        // don't close if in any of these areas
        const searchbar = document.getElementById('searchbox')
        const dropdown = document.getElementById('dropdown')
        const searchResults = document.getElementById('search-results')


        if (searchbar?.contains(event.target as Node)) {
            isInSearchbar = true
        }

        if (dropdown?.contains(event.target as Node)) {
            isInDropdown = true
        }

        if (searchResults?.contains(event.target as Node)){
            isInSearchResults = true
        }

        if (!isInDropdown && !isInSearchbar && !isInSearchResults) {
            // close and reset
            setSearchDropdownOpen(false)
            setSelectedSchool(null)
        }
    }

    const handleOpenDropdown = () => {
        setSearchDropdownOpen(!searchDropdownOpen)

        // reset search results when closing search
        if (!searchDropdownOpen) {
            setSearchResults(communityColleges)
        }

        // search bar will re-open if user selects again
        if (selectedSchool) {
            setSelectedSchool(null)
            setSearchDropdownOpen(true)
            // reset page position
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
    }

    const handleChildElementClick = (event: MouseEvent<HTMLInputElement, globalThis.MouseEvent>) => event.stopPropagation()

    const handleSearch = (event: { target: { value: any } }) => {
        const { value } = event.target

        //return all results if search string is empty
        if (value.length === 0) {
            setSearchResults(communityColleges)
            return
        }

        const results = fuse.search(value)

        const items = results.map((result) => result.item)

        if (items.length === 0) {
            items.push(noResults)
        }

        setSearchResults(items)
    }

    // handles keydown for the clickable div bar
    const onKeyDown = (event: { key: string }) => {
        if (event.key === 'Enter' || event.key === ' ') {
            if (!searchDropdownOpen) {
                handleOpenDropdown();
            }
        }
    }

    const handleSchoolSelection = (event: React.MouseEvent<HTMLLIElement>, selection: CommunityCollegesDto) => {
        setSearchDropdownOpen(false)
        setSelectedSchool(selection)
        setDropdownIsFocused(false)

        // scroll the search bar to top for school results display
        var element = document.getElementById("searchbox")
        element?.scrollIntoView()
    }

    const handleOnBlurEvent = (HTMLDivElement: FocusEvent<HTMLDivElement, Element>) => {
        if (!inputIsFocused && !dropdownIsFocused) {
            setSearchDropdownOpen(false)
            setSelectedSchool(null)
        }
    }

    const setInputOnFocus = (HTMLDivElement: FocusEvent<HTMLDivElement, Element>) => {
        setInputIsFocused(true)
        setDropdownIsFocused(false)
    }

    const setDropdownOnFocus = (HTMLDivElement: FocusEvent<HTMLLIElement, Element>) => {
        setDropdownIsFocused(true)
        setInputIsFocused(false)
    }


    function displaySearchResults(): JSX.Element {
        return (
            <ul id="dropdown" className="absolute bg-white text-mhicc-dark-slate font-inter w-full max-h-[20rem] overflow-y-auto rounded-b-xl drop-shadow-lg z-[999]">
                <div className="py-6 text:xl sm:text-2xl font-normal h-4/5 overflow-y-auto">
                    <>
                        {searchResults.map((school, index) => {
                            return (
                                <li key={index} role="button" className="w-full rounded-lg px-4 lg:px-8" onFocus={event => setDropdownOnFocus(event)} onClick={event => handleSchoolSelection(event, school)} >
                                    <button className="w-full hover:bg-mhicc-light-blue py-3 lg:mr-20 rounded-lg" id={school.title} disabled={school.title === noResults.title}>
                                        <span className='float-left pl-5 text-left'>{school.title}</span>
                                    </button>
                                </li>
                            )
                        })}
                    </>
                </div>
            </ul>
        )
    }

    return (
        <>
            <style>
                {
                    `
            #mhicc-search-input-field {
                background-color: #5475FF;
                border-color: #5475FF;
                color: #FFFFFF;
                outline: none;
                font-family: Inter;
                font-size: 18px;
                font-weight: 500;
            }

            @media screen and (min-width: 768px) and (max-width: 1079px) {
                #mhicc-search-input-field {
                    font-size: 24px;
                }
            }

            @media only screen and (min-width: 1080px) {
                #mhicc-search-input-field {
                    font-size: 32px;
                    font-weight: 600;
                }
            }
            `
                }
            </style>
            <div>
                <div tabIndex={0} onKeyDown={onKeyDown} id="searchbox" className="bg-mhicc-blue text-white h-16 sm:h-20 lg:h-28" onClick={handleOpenDropdown} >
                    <div className="px-7 md:px-20 py-5 lg:py-7 text-lg md:text-[1.9rem] font-medium md:font-normal font-inter flex items-center justify-between h-full">
                        {selectedSchool && <span>{selectedSchool.title}</span>}
                        {!searchDropdownOpen && !selectedSchool && <span className='lg:hidden'>Enter or select your school name</span>}
                        {!searchDropdownOpen && !selectedSchool && <span className='hidden lg:block'>Search and select your school name</span>}
                        {searchDropdownOpen && <div className="text-white inline pl-3 mr-auto">
                            <input
                                className=''
                                id='mhicc-search-input-field'
                                type='text'
                                onChange={handleSearch}
                                onClick={event => handleChildElementClick(event)}
                                onBlur={event => handleOnBlurEvent(event)}
                                onFocus={event => setInputOnFocus(event)}
                                autoFocus={true}
                            />
                        </div>}
                        <div className='inline ml-auto md:mt-auto md:h-6 md:w-8'>
                            {/* icon for mobile display */}
                            <img alt='expand icon' className='lg:hidden' src={Expand} />
                        </div>
                        <div className='flex lg:h-14 lg:w-14 xl:h-16 xl:w-16'>
                            {/* icon for larger screen display */}
                            <img alt='search icon' className='hidden lg:inline relative float-right' src={Search} />
                        </div>
                    </div>
                    <div className='mx-7 md:mx-20'>
                        <div className="relative">
                            {searchDropdownOpen &&
                                displaySearchResults()
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchBar

