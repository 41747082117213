import { useEffect, useState } from "react"
import { HelplineDto } from "../services/NationalCrisisHelplinesService"
import { ResourceCategoryDto } from "../services/ResourceCategoryService"
import HelplineCard from "./HelplineCard"
import ResourceModal from "./ResourceModal"
import Help from "../public/icons/help.png"


interface Props {
    id: string
    activeTab: string
    title: string
    content: HelplineDto[]
    resourceCategories: ResourceCategoryDto[]
}

const NationalTab = (props: Props) => {
    const { id, activeTab, title, content, resourceCategories } = props

    const [open, setOpen] = useState(false)

    const [resource, setResource] = useState<ResourceCategoryDto | null>(null)

    const handleOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)

    useEffect(() => {
        //prevent scrolling when the modal is open
        if (open) {
            document.body.style.overflow = 'hidden'
        }
        else {
            document.body.style.overflow = 'visible'
        }

        // set the resource 
        const matchingResource = resourceCategories.find(item => item.title === title)
        if (matchingResource !== undefined) {
            setResource(matchingResource)
        }
    }, [open])

    function showHelplines() {
        return (
            <div className="flex flex-col lg:flex-row lg:flex-wrap lg:mb-24">
                {content.map((helpline) => {
                    return (
                        <HelplineCard name={helpline.name} contactInformation={helpline.contactInformation} active={helpline.active} />
                    )
                })}
            </div>
        )
    }

    return (
        activeTab === id ?
            <div>
                <div className="text-mhicc-dark-slate font-inter">
                    <div className="flex flex-row items-center">
                        <div className="py-7 text-xl font-bold lg:text-2xl mr-2">
                            <span>{title}</span>
                        </div>
                        <button onClick={handleOpen}>
                            <div className="h-6 w-6">
                                <img src={Help} alt="question mark icon" />
                            </div>
                        </button>
                    </div>
                    <div>
                        {showHelplines()}
                    </div>
                </div>
                {resource && <ResourceModal open={open} onClose={handleClose} serviceResource={resource} />}
            </div>
            : null
    )
}

export default NationalTab