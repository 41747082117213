import sanitizeHtml from 'sanitize-html'

// remove all html tags
export const sanitizeWordPressHTML = html => {
  const clean = sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['a', 'span', 'p', 'ul', 'ol', 'li']),
    allowedAttributes: {
      a: ['href', 'name', 'target', 'rel'],
      class: ['class'],
    },
  })
  return clean
}

// restores '&' symbol for urls or other display in text / non-html display areas
export const restoreCharacters = text => text.replace(/&amp;/g, '&')