import { ResourceCategoryDto } from "../services/ResourceCategoryService"
import Close from "../public/icons/close_icon.png"

interface Props {
    open: boolean
    onClose: any
    serviceResource: ResourceCategoryDto
}

const ResourceModal = (props: Props) => {
    const { open, onClose, serviceResource } = props

    const altImage = () => {
        return [serviceResource.title, 'icon'].join(' ');
    }

    return (
        open ?
            <div onClick={onClose} className="fixed inset-0 flex lg:justify-center lg:items-center bg-black/30 z-[999]">
                <div onClick={e => e.stopPropagation()} className="bg-white rounded-xl flex flex-col mx-auto w-10/12 md:w-7/12 lg:w-2/5 lg:mx-0 mt-24 sm:mt-32 md:mt-36 lg:mt-40 max-h-[32rem] max-w-[32rem]">
                    <div className="">
                        <button onClick={onClose} className="pt-3 pr-3 rounded-lg relative float-right">
                            <img alt="close icon" src={Close} className="h-6 w-6"/>
                        </button>
                    </div>  
                    <div className="px-11 pt-5 lg:pt-2 pb-5 font-inter overflow-y-auto">            
                        <div className="w-1/3">
                            <img alt={altImage()} src={serviceResource.iconUrl} />
                        </div>
                        <div className="text-mhicc-purple text-xl font-bold pt-5">
                            <span>{serviceResource.resourceType}</span>
                        </div>
                        <div className="pt-6 pb-4 text-2xl lg:text-3xl font-inter font-bold ">
                            <span>{serviceResource.title}</span>
                        </div>
                        <div className="text-base lg:text-xl font-normal font-inter leading-7 pb-4">
                            <span>{serviceResource.description}</span>
                        </div>
                    </div>
                </div>
            </div>
            : null
    )
}

export default ResourceModal