import { sanitizeWordPressHTML } from "../utils/helper";
import { wpClient } from "./WpService"

export interface ResourceCategoryDto {
    title: string,
    description: string,
    iconUrl: string,
    resourceType: string,
    active: boolean,
    smallIconUrl: string,
}


export async function getAllResourceCategories(): Promise<ResourceCategoryDto[]> {
    const categoryResponse = await wpClient.get('/wp-json/wp/v2/results-category?acf_format=standard')

    const processedData: ResourceCategoryDto[] = []

    categoryResponse.data.forEach((category: {
        title: { rendered: any; }; acf: {
            small_icon: any; description: any; icon: any; resource_type: { name: any; }; active: any;
        };
    }) => {
        const processedCategory = {
            title: sanitizeWordPressHTML(category.title.rendered),
            description: sanitizeWordPressHTML(category.acf.description),
            iconUrl: sanitizeWordPressHTML(category.acf.icon),
            resourceType: sanitizeWordPressHTML(category.acf.resource_type.name),
            active: category.acf.active,
            smallIconUrl: sanitizeWordPressHTML(category.acf.small_icon),
        }

        processedData.push(processedCategory)
    })

    return processedData
}