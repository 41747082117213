import { MainContentDto } from '../services/MainContentService'

interface Props {
    content: MainContentDto
    isLoading: boolean
}

const MainSection = (props: Props) => {

    const { content, isLoading } = props
    const { imageUrl, title, description } = content

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-5 items-center pt-2 md:pb-8 lg:pb-10 px-7 md:px-20  bg-mhicc-light-blue">
                <div id="mhicc-main" className="py-6 md:col-span-2 md:py-0">
                    {
                        isLoading ? <MapSkeleton /> :
                            imageUrl && (
                                <img alt='Map of Michigan' src={imageUrl} />
                            )
                    }
                </div>
                {
                    isLoading ? <TextSkeleton /> : (
                        <div className="order-first font-inter text-mhicc-dark-slate md:order-last md:col-span-3 md:pl-16 lg:pl-18">
                            <div className="text-2xl md:text-3xl lg:text-4xl mb-3 lg:mb-5 font-bold md:font-extrabold ">
                                <span>{title}</span>
                            </div>
                            <div className="text-base/1.25rem font-normal lg:text-xl ">
                                <p>{description}</p>
                            </div>
                        </div>
                    )
                }

            </div>
        </>
    )
}
function TextSkeleton() {
    return (
        <div className='animate-pulse order-first md:order-last flex flex-col md:col-span-3 gap-y-3 md:pl-16 md:ml-6 lg:ml-12 xl:ml-10 mb-4'>
            <div className="h-14 mb-4 rounded-full bg-gray-400  "></div>
            <div className="h-6 rounded-full bg-gray-400 "></div>
            <div className="h-6 rounded-full bg-gray-400 "></div>
            <div className="h-6 rounded-full bg-gray-400 "></div>
            <div className="h-6 rounded-full bg-gray-400 "></div>
            <div className="h-6 rounded-full bg-gray-400 "></div>
            <div className="h-6 rounded-full bg-gray-400 "></div>
        </div>
    )
}
function MapSkeleton() {
    return (
        <div role='status' className='animate-pulse items-start w-full'>
            <div className="flex items-center justify-center w-80 h-80 sm:w-72 sm:h72 lg:w-[30rem] lg:h-[30rem] m-auto rounded bg-gray-400">
                <svg className="w-10 h-10 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
            </div>
        </div>
    )
}

export default MainSection