import { useState, useEffect } from "react"
import { ResourceCategoryDto } from "../services/ResourceCategoryService"
import ResourceModal from "./ResourceModal"


interface Props {
    serviceResource: ResourceCategoryDto
}

const SmallServiceCard = (props: Props) => {
    const { serviceResource } = props

    const { active, title, smallIconUrl } = serviceResource

    const [open, setOpen] = useState(false)

    useEffect(() => {
        //prevent scrolling when the modal is open
        if (open) {
          document.body.style.overflow = 'hidden'
        }
        else {
            document.body.style.overflow = 'visible'
        }
      }, [open])

    const handleOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)

    const altImage = [title, 'icon'].join(' ');

    return (
        <div>
            {active && smallIconUrl && (
                <div>
                    <button onClick={handleOpen}>
                         <div className="border-mhicc-purple lg:border-transparent hover:text-mhicc-purple hover:border-mhicc-purple active:text-mhicc-purple active:border-mhicc-purple border-2 border-transparent transition ease-out duration-150
                          flex flex-row items-center justify-center px-3 mr-3 rounded-lg py-3 mb-3 lg:mb-0">
                            <div className="flex mr-3 h-9 w-10">
                                 <img alt={altImage} src={smallIconUrl} className="items-center" />
                             </div>
                             <div className="font-inter font-semibold text-base text-left">
                                 <span>{title}</span>
                             </div>
                         </div>
                     </button>
                    <ResourceModal open={open} onClose={handleClose} serviceResource={serviceResource} />
               </div>
            )}
        </div>
    )
}

export default SmallServiceCard