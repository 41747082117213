import { ResourceCategoryDto } from "../services/ResourceCategoryService"
import SmallServiceCard from "./SmallServiceCard"

interface Props {
    name: string
    servicesOffered: ResourceCategoryDto[]
    active: boolean
    description: string
    howToAccess: string
}


const SchoolResourceCard = (props: Props) => {

    const { name, servicesOffered, active, description, howToAccess } = props

    function displayServicesOffered() {
        return (
            <div className="flex flex-row flex-wrap">
                { servicesOffered.map( (service, index) => {
                    return (
                        <SmallServiceCard key={index} serviceResource={service} />
                    )
                })}
            </div>
        )
    }

    return (
        active ?
            <div className="pb-8 text-mhicc-dark-slate flex flex-col lg:grid lg:grid-cols-3">
                <div className="px-6 lg:px-7 pb-5 bg-mhicc-light-purple lg:col-span-2 rounded-t-2xl lg:rounded-bl-2xl lg:rounded-tr-none">
                    <div className="pt-8 pb-6 text-xl lg:text-2xl font-inter font-extrabold">
                        <span>{name}</span>
                    </div>
                    <div className="">
                        {displayServicesOffered()}
                    </div>
                    <div className="pt-2 text-lg lg:text-xl font-normal font-inter leading-7 [&_a]:text-mhicc-purple [&_a]:underline [&_p]:pb-3">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: description
                            }}
                            className=""
                        ></div>
                    </div>
                </div> 
                <div className="px-6 lg:px-7 bg-mhicc-blue/[.10] lg:col-span-1 rounded-b-2xl lg:rounded-bl-none lg:rounded-tr-2xl">
                    <div className="pt-8 pb-5 text-base font-bold font-inter">
                        <span>How to Access This Resource</span>
                    </div>   
                    <div className="pb-9 text-lg font-medium font-inter leading-6 overflow-x-auto [&_a]:text-mhicc-purple [&_a]:underline [&_p]:pb-3" >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: howToAccess
                            }}
                            className=""
                        ></div>
                    </div> 
                </div>
            </div>
            : null
    )
}

export default SchoolResourceCard