import { useState, useEffect } from "react"
import { CommunityCollegeResource } from "../services/CommunityCollegeSearchService"
import { ResourceCategoryDto } from "../services/ResourceCategoryService"
import SchoolResourceCard from "./SchoolResourceCard"
import Help from "../public/icons/help.png"
import * as Constants from "../constants"
import ResourceModal from "./ResourceModal"


interface Props {
    id: string
    activeTab: string
    title: string
    content: CommunityCollegeResource[]
    resourceCategories: ResourceCategoryDto[]
}

const Tab = (props: Props) => {
    const { id, activeTab, title, content, resourceCategories } = props

    const serviceCategories: ResourceCategoryDto[] = resourceCategories.filter(category => category.resourceType === Constants.servicesResourceType)

    const [open, setOpen] = useState(false)

    const [resource, setResource] = useState<ResourceCategoryDto | null>(null)

    const handleOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)

    useEffect(() => {
        //prevent scrolling when the modal is open
        if (open) {
          document.body.style.overflow = 'hidden'
        }
        else {
            document.body.style.overflow = 'visible'
        }

        // set the resource 
        const matchingResource = resourceCategories.find(item => item.title === title)
        if (matchingResource !== undefined){
            setResource(matchingResource)
        }
      }, [open])


    function showResourceResults() {
        return (
            <div>
                {content.map((resource, index) => {
                    // map the services offered with corresponding resouce info to display for that service
                    const servicesDisplayItems: ResourceCategoryDto[] = resource.servicesOffered.map(function (service) {
                        const matchingService = serviceCategories.find(match => match.title === service)

                        const displayItem: ResourceCategoryDto = {
                            title: service,
                            iconUrl: matchingService ? matchingService.iconUrl : '',
                            active: matchingService ? matchingService.active : false,
                            smallIconUrl: matchingService ? matchingService.smallIconUrl : '',
                            description: matchingService ? matchingService.description : '',
                            resourceType: matchingService ? matchingService.resourceType : ''
                        }

                        return displayItem
                    })

                    return (
                        <SchoolResourceCard key={index} name={resource.name} servicesOffered={servicesDisplayItems} active={resource.active} description={resource.description} howToAccess={resource.howToAccess} />
                    )
                })}
            </div>
        )
    }

    return (
        activeTab === id ?
            <div>
                <div className="text-mhicc-dark-slate font-inter bg-white">
                    <div className="flex flex-row items-center">
                        <div className="py-7 text-xl font-bold lg:text-2xl mr-2">
                            <span>{title}</span>
                        </div>
                        <button onClick={handleOpen}>
                            <div className="h-6 w-6">
                                <img src={Help} alt="question mark icon" />
                            </div>
                        </button>
                    </div>

                    <div>
                        {showResourceResults()}
                    </div>
                </div>
                {resource && <ResourceModal open={open} onClose={handleClose} serviceResource={resource} />     }          
            </div>
        : null
    )
}

export default Tab