import { CommunityCollegesDto } from '../services/CommunityCollegeSearchService'
import SearchBar from './SearchBar'

interface Props {
    communityColleges: CommunityCollegesDto[]
    setSelectedSchool: any
    selectedSchool: CommunityCollegesDto | null
}

const SearchSection = (props: Props) => {
    const { communityColleges, setSelectedSchool, selectedSchool } = props

    return (
        <div className='sticky top-0 lg:top-[98px]'>
            <SearchBar selectedSchool={selectedSchool} setSelectedSchool={setSelectedSchool} communityColleges={communityColleges} />
        </div>
    )
}

export default SearchSection