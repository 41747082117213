import { useState } from "react"
import { CommunityCollegeResource, CommunityCollegesDto } from "../services/CommunityCollegeSearchService"
import { HelplineDto, NationalCrisisHelplinesDto } from "../services/NationalCrisisHelplinesService"
import Tab from "./Tab"
import TabNavItem from "./TabNavItem"
import MobileTabNavItem from "./MobileTabNavItem"
import NationalTab from "./NationalTab"
import { ResourceCategoryDto } from "../services/ResourceCategoryService"
import * as Constants from "../constants"

interface Props {
    selectedSchool: CommunityCollegesDto
    helplines: NationalCrisisHelplinesDto
    resourceCategories: ResourceCategoryDto[]
}

const SearchResults = (props: Props) => {
    const { selectedSchool, helplines, resourceCategories } = props

    const counselingServices: CommunityCollegeResource[] = selectedSchool.resources.filter(resource => resource.mentalHealthCategory === Constants.counselingServicesTab)
    const localCrisisResources: CommunityCollegeResource[] = selectedSchool.resources.filter(resource => resource.mentalHealthCategory === Constants.crisisResourceTab)
    const nationalHelplines: HelplineDto[] = helplines.crisisHelplines

    const couselingServicesTitle = counselingServices[0].mentalHealthCategory
    const localCrisisResouceTitle = localCrisisResources[0].mentalHealthCategory
    const nationalHelplinesTitle = helplines.title

    const [activeTab, setActiveTab] = useState(couselingServicesTitle)
    const [collapseTabs, setCollapseTabs] = useState(true)

    const createTabs = () => {
        return (
            <div>
                <div className="border-b-[3px]">
                    <ul className=" border-mhicc-light-purple">
                        {/* get tab navigation for larger devices */}
                        <TabNavItem title={couselingServicesTitle} activeTab={activeTab} setActiveTab={setActiveTab} /> 
                        <TabNavItem title={localCrisisResouceTitle} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <TabNavItem title={nationalHelplinesTitle} activeTab={activeTab} setActiveTab={setActiveTab} />
                        {/* get tab navigation for mobile devices */}
                        <MobileTabNavItem title={couselingServicesTitle} activeTab={activeTab} setActiveTab={setActiveTab} mobileCollapseTabs={collapseTabs} isFirstTab={true} collapseTabs={collapseTabs} setCollapseTabs={setCollapseTabs} />
                        <MobileTabNavItem title={localCrisisResouceTitle} activeTab={activeTab} setActiveTab={setActiveTab} mobileCollapseTabs={collapseTabs} isFirstTab={false} collapseTabs={collapseTabs} setCollapseTabs={setCollapseTabs} />
                        <MobileTabNavItem title={nationalHelplinesTitle} activeTab={activeTab} setActiveTab={setActiveTab} mobileCollapseTabs={collapseTabs} isFirstTab={false} collapseTabs={collapseTabs} setCollapseTabs={setCollapseTabs} />
                    </ul>
                </div>
                <div>
                    <Tab content={counselingServices} title={couselingServicesTitle} id={couselingServicesTitle} activeTab={activeTab} resourceCategories={resourceCategories} />
                    <Tab content={localCrisisResources} title={localCrisisResouceTitle} id={localCrisisResouceTitle} activeTab={activeTab} resourceCategories={resourceCategories} />
                    <NationalTab content={nationalHelplines} title={nationalHelplinesTitle} id={nationalHelplinesTitle} activeTab={activeTab} resourceCategories={resourceCategories}  />
                </div>
            </div>
        )
    }

    return (
        <div id="search-results" className="bg-white px-7 md:px-20 lg:pb-16">
            <div className="bg-white py-7 lg:py-8 font-inter text-2xl lg:text-4xl font-semibold text-mhicc-dark-slate">
                <span>Mental Health Resources at&nbsp;
                    <span className="italic text-mhicc-purple">{selectedSchool.title}</span>
                </span>
            </div>
            <div>
                {createTabs()}
            </div>         
        </div>
    )
}

export default SearchResults