import { useState, useEffect } from 'react'
import { useErrorBoundary } from 'react-error-boundary';
import MainSection from './MainSection'
import SearchSection from './SearchSection'
import ResourceCategorySection from './ResourceCategorySection'
import { MainContentDto, getMainContent } from '../services/MainContentService'
import { ResourceCategoryDto, getAllResourceCategories } from '../services/ResourceCategoryService'
import { CommunityCollegesDto, getAllCommunityColleges } from '../services/CommunityCollegeSearchService'
import { NationalCrisisHelplinesDto, getAllNationalCrisisHelplines } from '../services/NationalCrisisHelplinesService'
import SearchResults from './SearchResults'


const StudentPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [mainContent, setMainContent] = useState<MainContentDto>({
        title: "",
        description: "",
        imageUrl: "",
        active: false,
    })

    const [resourceCategories, setResourceCategories] = useState<ResourceCategoryDto[]>([])

    const [nationalCrisisHelplines, setNationalCrisisHelplines] = useState<NationalCrisisHelplinesDto>({
        title: "",
        crisisHelplines: []
    })

    const [communityColleges, setCommunityColleges] = useState<CommunityCollegesDto[]>([])

    const [selectedSchool, setSelectedSchool] = useState<CommunityCollegesDto | null>(null)
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        async function fetchData(){
            const content = await getMainContent()
            const categories = await getAllResourceCategories()
            const communityColleges = await getAllCommunityColleges()
            const helplines = await getAllNationalCrisisHelplines()

            setMainContent(content)
            setCommunityColleges(communityColleges)
            setResourceCategories(categories)
            setNationalCrisisHelplines(helplines)
            setIsLoading(false)
        }

        //  all wp api calls handled together
        fetchData().catch((error) => {
            console.log('Error: ', error)
            showBoundary(error);
        })

    }, [showBoundary]);

    return (
        <div className="font-inter bg-mhicc-light-gray min-h-screen">
            <MainSection content={mainContent} isLoading={isLoading}/>
            <SearchSection selectedSchool={selectedSchool} setSelectedSchool={setSelectedSchool} communityColleges={communityColleges}  />
            {selectedSchool && <SearchResults selectedSchool={selectedSchool} helplines={nationalCrisisHelplines} resourceCategories={resourceCategories} />}
            <ResourceCategorySection categories={resourceCategories} selectedSchool={selectedSchool} isLoading={isLoading}/>
        </div>
    )
}

export default StudentPage