import { useState, useEffect } from 'react';
import BackArrow from '././../public/icons/arrow_back_ios_new.png'
import ResourceModal from './ResourceModal';
import { ResourceCategoryDto } from '../services/ResourceCategoryService';

interface Props {
    resource: ResourceCategoryDto
    isLoading: boolean
}

const ResourceCard = (props: Props) => {
    const { resource, isLoading } = props

    const { iconUrl, title } = resource

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)

    useEffect(() => {
        //prevent scrolling when the modal is open
        if (open) {
          document.body.style.overflow = 'hidden'
        }
        else {
            document.body.style.overflow = 'visible'
        }
      }, [open])

    const altImage = [title, 'icon'].join(' ');


    return (
        <div>
             <button className="w-full" onClick={handleOpen}>
                <div className="flex flex-row justify-center items-center my-3 lg:my-4 mx-3">
                    {
                        isLoading ?
                            (<div className="rounded-full bg-gray-400 h-14 w-14 pr-3 animate-pulse"></div>)
                            :
                        ( iconUrl && (
                                <div className="h-14 w-16 flex items-center">
                                    <img alt={altImage} src={iconUrl} />
                                </div>
                                ))
                    }
                    {
                        isLoading ?
                            (<div className="flex-1 w-40 h-4 bg-gray-400 rounded-full m-5 animate-pulse"></div>)
                            :
                            (<div className="flex-1 text-base md:text-lg font-medium text-left pl-5">
                                <span>{title}</span>
                            </div >)
                    }
                    <div className="h-10 w-10 pl-5 flex items-center">
                        <img alt='arrow icon' src={BackArrow} />
                    </div>
                </div >
            </button>
            <ResourceModal open={open} onClose={handleClose} serviceResource={resource} />
        </div >
    )
}

export default ResourceCard