import { restoreCharacters, sanitizeWordPressHTML } from "../utils/helper"
import { wpClient } from "./WpService"

export interface CommunityCollegesDto {
    title: string,
    active: boolean,
    resources: CommunityCollegeResource[]
}

export interface CommunityCollegeResource {
    name: string,
    description: string,
    active: boolean,
    howToAccess: string,
    mentalHealthCategory: string,
    servicesOffered: string[]
}

export interface Service {
    label: string
}


export async function getAllCommunityColleges():  Promise<CommunityCollegesDto[]> {
    const communityCollegeResponse = await wpClient.get('/wp-json/wp/v2/community-college?acf_format=standard&per_page=50')

    const communityCollegeData = communityCollegeResponse.data

    const communityColleges: CommunityCollegesDto[] = []

    communityCollegeData.forEach(((college: { title: { rendered: any }; acf: { active: any; resources_and_services: any } }) => {
        const processedData: CommunityCollegesDto = {
        title: sanitizeWordPressHTML(college.title.rendered),
        active: college.acf.active,
        resources: []
        }

        const resourceDataArray = college.acf.resources_and_services

         resourceDataArray.forEach((school: { name: any; description: any; active: any; how_to_access_this_resource: any; mental_health_category: { label: any }; services_offered: [] }) => {
            const resource: CommunityCollegeResource = {
                name: restoreCharacters(sanitizeWordPressHTML(school.name)) , // restore any & characters in resource name
                description: sanitizeWordPressHTML(school.description),
                active: school.active,
                howToAccess: sanitizeWordPressHTML(school.how_to_access_this_resource),
                mentalHealthCategory: sanitizeWordPressHTML(school.mental_health_category.label),
                servicesOffered: []
            }
    
            school.services_offered.forEach((service: {value: any, label: any}) => {
                resource.servicesOffered.push(sanitizeWordPressHTML(service.label))
            })
    
            processedData.resources.push(resource)
        })

        communityColleges.push(processedData)
    }))

    const sortedResults = communityColleges.sort((a,b) => a.title.localeCompare(b.title))

    const activeSchools = sortedResults.filter(state => state.active === true)
 
    return activeSchools
}