import Indicator from "../public/icons/active_tab.png"

interface Props {
    title: string
    activeTab: string
    setActiveTab: any
}

const TabNavItem = (props: Props) => {
    const { title, activeTab, setActiveTab } = props

    const handleClick = () => setActiveTab(title)
    

    return (
        activeTab === title ? 
            <button onClick={handleClick} className={`hidden lg:inline-block pr-14 pt-6 font-inter text-xl font-medium text-mhicc-dark-slate activeTab === id ? "active" : ""`}>
                <div>
                <span>{title}</span>
                <img alt='active tabe indicator' className="pt-2 mx-auto" src={Indicator} />
                </div>
            </button>    
        :
        <button onClick={handleClick} className={`hidden lg:inline-block pr-14 pt-6 font-inter text-xl font-medium text-mhicc-dark-slate activeTab === id ? "active" : ""`}><span>{title}</span></button>      
    )
}

export default TabNavItem