import { sanitizeWordPressHTML } from "../utils/helper"
import { wpClient } from "./WpService"

export interface MainContentDto {
    title: string,
    description: string,
    imageUrl: string,
    active: boolean,
}

export async function getMainContent(): Promise<MainContentDto>{
        const contentResponse = await wpClient('/wp-json/wp/v2/student-page-content?acf_format=standard')

        const processedData: MainContentDto = { 
            title: sanitizeWordPressHTML(contentResponse.data[0].title.rendered),
            description: sanitizeWordPressHTML(contentResponse.data[0].acf.description),
            imageUrl: sanitizeWordPressHTML(contentResponse.data[0].acf.main_image),
            active: contentResponse.data[0].acf.active,
        }
    
        return processedData
}
