import { sanitizeWordPressHTML } from "../utils/helper"
import { wpClient } from "./WpService"

export interface NationalCrisisHelplinesDto {
    title: string,
    crisisHelplines: HelplineDto[]
}

export interface HelplineDto {
    name: string,
    contactInformation: string,
    active: boolean
}


export async function getAllNationalCrisisHelplines(): Promise<NationalCrisisHelplinesDto>{
    const crisisHelplineResponse = await wpClient.get('/wp-json/wp/v2/national-crisis-help?acf_format=standard')

    const helplineData = crisisHelplineResponse.data[0]

    const processedData: NationalCrisisHelplinesDto = {
        title: sanitizeWordPressHTML(helplineData.title.rendered),
        crisisHelplines: []
    }

    helplineData.acf.national_crisis_helpline.forEach((helpline: { helpline_name: any; contact_information: any; active: any }) => {
        const crisisHelpline = {
            name: sanitizeWordPressHTML(helpline.helpline_name),
            contactInformation: sanitizeWordPressHTML(helpline.contact_information),
            active: helpline.active
        }

        processedData.crisisHelplines.push(crisisHelpline)
    })

   return processedData
}