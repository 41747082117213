import ExpandIcon from "../public/icons/expand_more.png"

interface Props {
    title: string
    activeTab: string
    setActiveTab: any
    mobileCollapseTabs: boolean
    isFirstTab: boolean
    collapseTabs: boolean
    setCollapseTabs: any
}

const TabNavItem = (props: Props) => {
    const { title, setActiveTab, mobileCollapseTabs, isFirstTab, collapseTabs, setCollapseTabs } = props

    const handleActiveClick = () => {
        setActiveTab(title);
    };

    const handleCollapseTabs = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setCollapseTabs(!collapseTabs)
    }

    return (
        isFirstTab || !mobileCollapseTabs ?
            <li onClick={handleActiveClick} className={`lg:hidden py-4 font-inter text-lg font-medium text-mhicc-dark-slate activeTab === id ? "active" : ""`}>
                {title}
                {isFirstTab && 
                    <button onClick={event => handleCollapseTabs(event)} className="inline float-right">
                        <div className="h-8 w-10 md:pr-3">
                            <img alt="expand icon" className="inline float-righ mt-auto" src={ExpandIcon} />
                        </div>
                      
                    </button>}
            </li>
            : null
    )
}

export default TabNavItem