interface Props {
    name: string,
    contactInformation: string,
    active: boolean
}

const HelplineCard = (props: Props) => {
    const { name, contactInformation, active } = props

    return (
        active ?
        <div className="pb-8 lg:mb-6 mx-auto text-mhicc-dark-slate flex flex-col max-w-[320px]">
                <div className="px-6 lg:px-7 pb-10 bg-mhicc-light-purple lg:col-span-2 rounded-2xl text-center">
                    <div className="pt-10 pb-5 text-2xl font-inter font-extrabold">
                        <span>{name}</span>
                    </div>
                    <div className="text-lg font-normal font-inter">
                        <span>{contactInformation}</span>
                    </div>
                </div> 
            </div>
            : null
    )
}

export default HelplineCard