import { FallbackProps } from "react-error-boundary"

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
    return (
        <div className="grid justify-items-center m-12 gap-y-12" role="alert">
            <pre className="text-xl text-red-500">{"Error: " + error.message}</pre>
            <p className="text-xl text-gray-700">Please reload the page by pressing the button or try again later</p>
            <button onClick={resetErrorBoundary} className=" text-xl text-white bg-mhicc-blue rounded-lg relative items-center w-40 h-20">Reload</button>
        </div>
    )
}
export default ErrorFallback